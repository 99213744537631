// Labels

.label {
	padding: 3px 5px 2px;
	text-transform: uppercase;
	font-size: font-size(small);
	border-width: 1px;
	border-style: solid;

	&--success {
		color: $colour--success;
		border-color: $colour--success;
	}
}