// Display

.display--mobile {
	display: block;

	@include breakpoint($break-lg) {
		display: none;
	}
}

.display--inline-block-mobile {
	display: inline-block;

	@include breakpoint($break-lg) {
		display: none;
	}
}

.display--hide-mobile {
	display: none;

	@include breakpoint($break-lg) {
		display: block;
	}
}