// Shame

#main {
	padding-top: $spacing-md;
	background-color: #fff;
	//background-image: url('../images/background-illustration-main.png');
	//background-repeat: repeat-x;
	//background-size: 380px, contain;
}

.result__actions {
	.btn {
		margin-bottom: $spacing-sm;
	}
}

#fly-baby {
	padding-right: 0;
	padding-left: 0;
}