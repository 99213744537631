// Loader

// Loading icon settings

$loader-color:					$colour--brand;
$loader-size: 					20px;
$loader-height: 				20px;
$loader-border-size: 			8px;
$loader-gap: 					30px;
$loader-animation-duration: 	1s;	

.loader {
	position: relative;
	width: 200px;
	height: 200px;
	margin: 0 auto;
}

.loader__icon {
	@include loader12;
	top: 50%;
	margin: -50% auto 0 auto;
}