// Header

.uber-nav {
	padding: $spacing-sm 0;

	&__title {
		padding-right: $spacing-xs;
		font-size: font-size(small);
		opacity: $transparent-secondary;

		&:hover {
			color: $colour--success;
		}
	}
}

.logo {

	padding: 0 0 $spacing $spacing;
	
	&__image {
		padding-bottom: $spacing-xs;
	}

	&__label {
		display: block;
		font-size: font-size(body);
		opacity: $transparent-secondary;
		color: $colour--white;
		font-weight: 300;
	}
}

.header {
	//margin-bottom: $spacing-md;
	padding: $spacing-md 0 0;
	background-color: $colour--black;
	background-image: url('../images/background-illustration-white.png'), linear-gradient(-150deg, lighten($colour--black,5%) 0%, $colour--black 97%);
	background-size: 380px, contain;

	&__nav {}
	
}
