/*!
 * Generated with CSS Flag Sprite generator (https://www.flag--sprites.com/)
 */
.flag {
    display: inline-block;
    width: 16px;
    height: 11px;
    margin-right: $spacing-xs;
    background: url('../images/flags.png') no-repeat;
}

.flag--all {
    background-image: none;

    &.flag {
        width: auto;
        height: auto;
        margin: 0;
    }
}

.flag.flag--gw {
    background-position: -144px -55px;
}

.flag.flag--gu {
    background-position: -128px -55px;
}

.flag.flag--gt {
    background-position: -112px -55px;
}

.flag.flag--gs {
    background-position: -96px -55px;
}

.flag.flag--gr {
    background-position: -80px -55px;
}

.flag.flag--gq {
    background-position: -64px -55px;
}

.flag.flag--gp {
    background-position: -48px -55px;
}

.flag.flag--gy {
    background-position: -160px -55px;
}

.flag.flag--gg {
    background-position: -208px -44px;
}

.flag.flag--gf {
    background-position: -192px -44px;
}

.flag.flag--ge {
    background-position: -176px -44px;
}

.flag.flag--gd {
    background-position: -160px -44px;
}

.flag.flag--uk, .flag.flag--co\.uk {
    background-position: -144px -44px;
}

.flag.flag--ga {
    background-position: -128px -44px;
}

.flag.flag--gn {
    background-position: -32px -55px;
}

.flag.flag--gm {
    background-position: -16px -55px;
}

.flag.flag--gl {
    background-position: 0 -55px;
}

.flag.flag--gi {
    background-position: -240px -44px;
}

.flag.flag--gh {
    background-position: -224px -44px;
}

.flag.flag--lb {
    background-position: -208px -77px;
}

.flag.flag--lc {
    background-position: -224px -77px;
}

.flag.flag--la {
    background-position: -192px -77px;
}

.flag.flag--tv {
    background-position: -32px -154px;
}

.flag.flag--tw {
    background-position: -48px -154px;
}

.flag.flag--tt {
    background-position: -16px -154px;
}

.flag.flag--tr {
    background-position: 0 -154px;
}

.flag.flag--lk {
    background-position: 0 -88px;
}

.flag.flag--li {
    background-position: -240px -77px;
}

.flag.flag--lv {
    background-position: -80px -88px;
}

.flag.flag--to {
    background-position: -240px -143px;
}

.flag.flag--lt {
    background-position: -48px -88px;
}

.flag.flag--lu {
    background-position: -64px -88px;
}

.flag.flag--lr {
    background-position: -16px -88px;
}

.flag.flag--ls {
    background-position: -32px -88px;
}

.flag.flag--th {
    background-position: -128px -143px;
}

.flag.flag--tf {
    background-position: -96px -143px;
}

.flag.flag--tg {
    background-position: -112px -143px;
}

.flag.flag--td {
    background-position: -80px -143px;
}

.flag.flag--tc {
    background-position: -64px -143px;
}

.flag.flag--ly {
    background-position: -96px -88px;
}

.flag.flag--do {
    background-position: -112px -33px;
}

.flag.flag--dm {
    background-position: -96px -33px;
}

.flag.flag--dj {
    background-position: -64px -33px;
}

.flag.flag--dk {
    background-position: -80px -33px;
}

.flag.flag--um {
    background-position: -112px -154px;
}

.flag.flag--de {
    background-position: -48px -33px;
}

.flag.flag--ye {
    background-position: -96px -165px;
}

.flag.flag--dz {
    background-position: -128px -33px;
}

.flag.flag--uy {
    background-position: -144px -154px;
}

.flag.flag--yt {
    background-position: -112px -165px;
}

.flag.flag--catalonia {
    background-position: -32px -22px;
}

.flag.flag--vu {
    background-position: -16px -165px;
}

.flag.flag--qa {
    background-position: -128px -121px;
}

.flag.flag--tm {
    background-position: -208px -143px;
}

.flag.flag--england {
    background-position: -208px -33px;
}

.flag.flag--eh {
    background-position: -192px -33px;
}

.flag.flag--wf {
    background-position: -48px -165px;
}

.flag.flag--ee {
    background-position: -160px -33px;
}

.flag.flag--eg {
    background-position: -176px -33px;
}

.flag.flag--za {
    background-position: -128px -165px;
}

.flag.flag--ec {
    background-position: -144px -33px;
}

.flag.flag--www, .flag.flag--com {
    background-position: -128px -154px;
}

.flag.flag--eu {
    background-position: -16px -44px;
}

.flag.flag--et {
    background-position: 0 -44px;
}

.flag.flag--zw {
    background-position: -176px -165px;
}

.flag.flag--es {
    background-position: -240px -33px;
}

.flag.flag--er {
    background-position: -224px -33px;
}

.flag.flag--ru {
    background-position: -192px -121px;
}

.flag.flag--rw {
    background-position: -208px -121px;
}

.flag.flag--rs {
    background-position: -176px -121px;
}

.flag.flag--re {
    background-position: -144px -121px;
}

.flag.flag--it {
    background-position: -176px -66px;
}

.flag.flag--ro {
    background-position: -160px -121px;
}

.flag.flag--tk {
    background-position: -176px -143px;
}

.flag.flag--tz {
    background-position: -64px -154px;
}

.flag.flag--bd {
    background-position: -16px -11px;
}

.flag.flag--be {
    background-position: -32px -11px;
}

.flag.flag--bf {
    background-position: -48px -11px;
}

.flag.flag--bg {
    background-position: -64px -11px;
}

.flag.flag--vg {
    background-position: -224px -154px;
}

.flag.flag--ba {
    background-position: -240px 0;
}

.flag.flag--bb {
    background-position: 0 -11px;
}

.flag.flag--tibet {
    background-position: -144px -143px;
}

.flag.flag--bm {
    background-position: -112px -11px;
}

.flag.flag--bn {
    background-position: -128px -11px;
}

.flag.flag--bo {
    background-position: -144px -11px;
}

.flag.flag--bh {
    background-position: -80px -11px;
}

.flag.flag--bj {
    background-position: -96px -11px;
}

.flag.flag--bt {
    background-position: -192px -11px;
}

.flag.flag--jm {
    background-position: -208px -66px;
}

.flag.flag--bv {
    background-position: -208px -11px;
}

.flag.flag--bw {
    background-position: -224px -11px;
}

.flag.flag--ws {
    background-position: -64px -165px;
}

.flag.flag--br {
    background-position: -160px -11px;
}

.flag.flag--bs {
    background-position: -176px -11px;
}

.flag.flag--je {
    background-position: -192px -66px;
}

.flag.flag--by {
    background-position: -240px -11px;
}

.flag.flag--bz {
    background-position: 0 -22px;
}

.flag.flag--tn {
    background-position: -224px -143px;
}

.flag.flag--om {
    background-position: -144px -110px;
}

.flag.flag--zm {
    background-position: -160px -165px;
}

.flag.flag--ua {
    background-position: -80px -154px;
}

.flag.flag--jo {
    background-position: -224px -66px;
}

.flag.flag--mz {
    background-position: -192px -99px;
}

.flag.flag--ck {
    background-position: -128px -22px;
}

.flag.flag--xk {
    background-position: -80px -165px;
}

.flag.flag--ci {
    background-position: -112px -22px;
}

.flag.flag--ch {
    background-position: -96px -22px;
}

.flag.flag--co {
    background-position: -192px -22px;
}

.flag.flag--cn {
    background-position: -176px -22px;
}

.flag.flag--cm {
    background-position: -160px -22px;
}

.flag.flag--cl {
    background-position: -144px -22px;
}

.flag.flag--ca {
    background-position: -16px -22px;
}

.flag.flag--cg {
    background-position: -80px -22px;
}

.flag.flag--cf {
    background-position: -64px -22px;
}

.flag.flag--cd {
    background-position: -48px -22px;
}

.flag.flag--cz {
    background-position: -32px -33px;
}

.flag.flag--cy {
    background-position: -16px -33px;
}

.flag.flag--vc {
    background-position: -192px -154px;
}

.flag.flag--cr {
    background-position: -208px -22px;
}

.flag.flag--cw {
    background-position: 0 -33px;
}

.flag.flag--cv {
    background-position: -240px -22px;
}

.flag.flag--cu {
    background-position: -224px -22px;
}

.flag.flag--ve {
    background-position: -208px -154px;
}

.flag.flag--pr {
    background-position: -48px -121px;
}

.flag.flag--ps {
    background-position: -64px -121px;
}

.flag.flag--pw {
    background-position: -96px -121px;
}

.flag.flag--pt {
    background-position: -80px -121px;
}

.flag.flag--py {
    background-position: -112px -121px;
}

.flag.flag--tl {
    background-position: -192px -143px;
}

.flag.flag--iq {
    background-position: -128px -66px;
}

.flag.flag--pa {
    background-position: -160px -110px;
}

.flag.flag--pf {
    background-position: -192px -110px;
}

.flag.flag--pg {
    background-position: -208px -110px;
}

.flag.flag--pe {
    background-position: -176px -110px;
}

.flag.flag--pk {
    background-position: -240px -110px;
}

.flag.flag--ph, .flag.flag--com\.ph {
    background-position: -224px -110px;
}

.flag.flag--pn {
    background-position: -32px -121px;
}

.flag.flag--kurdistan {
    background-position: -128px -77px;
}

.flag.flag--pl {
    background-position: 0 -121px;
}

.flag.flag--pm {
    background-position: -16px -121px;
}

.flag.flag--hr {
    background-position: -224px -55px;
}

.flag.flag--ht {
    background-position: -240px -55px;
}

.flag.flag--hu {
    background-position: 0 -66px;
}

.flag.flag--hk {
    background-position: -176px -55px;
}

.flag.flag--hn {
    background-position: -208px -55px;
}

.flag.flag--vn {
    background-position: 0 -165px;
}

.flag.flag--hm {
    background-position: -192px -55px;
}

.flag.flag--jp {
    background-position: -240px -66px;
}

.flag.flag--wales {
    background-position: -32px -165px;
}

.flag.flag--me {
    background-position: -160px -88px;
}

.flag.flag--md {
    background-position: -144px -88px;
}

.flag.flag--mg {
    background-position: -176px -88px;
}

.flag.flag--ma {
    background-position: -112px -88px;
}

.flag.flag--mc {
    background-position: -128px -88px;
}

.flag.flag--uz {
    background-position: -160px -154px;
}

.flag.flag--mm {
    background-position: -240px -88px;
}

.flag.flag--ml {
    background-position: -224px -88px;
}

.flag.flag--mo {
    background-position: -16px -99px;
}

.flag.flag--mn {
    background-position: 0 -99px;
}

.flag.flag--mh {
    background-position: -192px -88px;
}

.flag.flag--mk {
    background-position: -208px -88px;
}

.flag.flag--mu {
    background-position: -112px -99px;
}

.flag.flag--mt {
    background-position: -96px -99px;
}

.flag.flag--mw {
    background-position: -144px -99px;
}

.flag.flag--mv {
    background-position: -128px -99px;
}

.flag.flag--mq {
    background-position: -48px -99px;
}

.flag.flag--mp {
    background-position: -32px -99px;
}

.flag.flag--ms {
    background-position: -80px -99px;
}

.flag.flag--mr {
    background-position: -64px -99px;
}

.flag.flag--im {
    background-position: -80px -66px;
}

.flag.flag--ug {
    background-position: -96px -154px;
}

.flag.flag--my {
    background-position: -176px -99px;
}

.flag.flag--mx {
    background-position: -160px -99px;
}

.flag.flag--il {
    background-position: -64px -66px;
}

.flag.flag--va {
    background-position: -176px -154px;
}

.flag.flag--sa {
    background-position: -224px -121px;
}

.flag.flag--ae {
    background-position: -16px 0;
}

.flag.flag--ad {
    background-position: 0 0;
}

.flag.flag--ag {
    background-position: -48px 0;
}

.flag.flag--af {
    background-position: -32px 0;
}

.flag.flag--ai {
    background-position: -64px 0;
}

.flag.flag--vi {
    background-position: -240px -154px;
}

.flag.flag--is {
    background-position: -160px -66px;
}

.flag.flag--ir {
    background-position: -144px -66px;
}

.flag.flag--am {
    background-position: -96px 0;
}

.flag.flag--al {
    background-position: -80px 0;
}

.flag.flag--ao {
    background-position: -128px 0;
}

.flag.flag--an {
    background-position: -112px 0;
}

.flag.flag--as {
    background-position: -160px 0;
}

.flag.flag--ar {
    background-position: -144px 0;
}

.flag.flag--au {
    background-position: -192px 0;
}

.flag.flag--at {
    background-position: -176px 0;
}

.flag.flag--aw {
    background-position: -208px 0;
}

.flag.flag--in, .flag.flag--co\.in {
    background-position: -96px -66px;
}

.flag.flag--ic {
    background-position: -16px -66px;
}

.flag.flag--az {
    background-position: -224px 0;
}

.flag.flag--ie {
    background-position: -48px -66px;
}

.flag.flag--id {
    background-position: -32px -66px;
}

.flag.flag--zanzibar {
    background-position: -144px -165px;
}

.flag.flag--ni {
    background-position: -32px -110px;
}

.flag.flag--nl {
    background-position: -48px -110px;
}

.flag.flag--no {
    background-position: -64px -110px;
}

.flag.flag--na {
    background-position: -208px -99px;
}

.flag.flag--nc {
    background-position: -224px -99px;
}

.flag.flag--scotland {
    background-position: -16px -132px;
}

.flag.flag--ne {
    background-position: -240px -99px;
}

.flag.flag--nf {
    background-position: 0 -110px;
}

.flag.flag--ng {
    background-position: -16px -110px;
}

.flag.flag--nz {
    background-position: -128px -110px;
}

.flag.flag--sh {
    background-position: -80px -132px;
}

.flag.flag--np {
    background-position: -80px -110px;
}

.flag.flag--so {
    background-position: -176px -132px;
}

.flag.flag--nr {
    background-position: -96px -110px;
}

.flag.flag--nu {
    background-position: -112px -110px;
}

.flag.flag--somaliland {
    background-position: -192px -132px;
}

.flag.flag--fr {
    background-position: -112px -44px;
}

.flag.flag--io {
    background-position: -112px -66px;
}

.flag.flag--sv {
    background-position: 0 -143px;
}

.flag.flag--sb {
    background-position: -240px -121px;
}

.flag.flag--fi {
    background-position: -32px -44px;
}

.flag.flag--fj {
    background-position: -48px -44px;
}

.flag.flag--fk {
    background-position: -64px -44px;
}

.flag.flag--fm {
    background-position: -80px -44px;
}

.flag.flag--fo {
    background-position: -96px -44px;
}

.flag.flag--tj {
    background-position: -160px -143px;
}

.flag.flag--sz {
    background-position: -48px -143px;
}

.flag.flag--sy {
    background-position: -32px -143px;
}

.flag.flag--sx {
    background-position: -16px -143px;
}

.flag.flag--kg {
    background-position: -16px -77px;
}

.flag.flag--ke {
    background-position: 0 -77px;
}

.flag.flag--ss {
    background-position: -224px -132px;
}

.flag.flag--sr {
    background-position: -208px -132px;
}

.flag.flag--ki {
    background-position: -48px -77px;
}

.flag.flag--kh {
    background-position: -32px -77px;
}

.flag.flag--kn {
    background-position: -80px -77px;
}

.flag.flag--km {
    background-position: -64px -77px;
}

.flag.flag--st {
    background-position: -240px -132px;
}

.flag.flag--sk {
    background-position: -112px -132px;
}

.flag.flag--kr {
    background-position: -112px -77px;
}

.flag.flag--si {
    background-position: -96px -132px;
}

.flag.flag--kp {
    background-position: -96px -77px;
}

.flag.flag--kw {
    background-position: -144px -77px;
}

.flag.flag--sn {
    background-position: -160px -132px;
}

.flag.flag--sm {
    background-position: -144px -132px;
}

.flag.flag--sl {
    background-position: -128px -132px;
}

.flag.flag--sc {
    background-position: 0 -132px;
}

.flag.flag--kz {
    background-position: -176px -77px;
}

.flag.flag--ky {
    background-position: -160px -77px;
}

.flag.flag--sg {
    background-position: -64px -132px;
}

.flag.flag--se {
    background-position: -48px -132px;
}

.flag.flag--sd {
    background-position: -32px -132px;
}
