//Footer

.footer {
	padding: $spacing-xl 0;

	&__list {

	}

	&__item {
		padding-right: $spacing;
		opacity: $transparent-secondary;
		&:hover {
			opacity: $transparent-none;
		}
	}

	&__link {
	}
}