// Defaults

// Spacing
$spacing: 						24px;
$spacing-xs:					$spacing/2;
$spacing-sm:					$spacing/1.5;
$spacing-md:					$spacing*1.5;
$spacing-lg:					$spacing*2;
$spacing-xl:					$spacing*4;

// Berakpoints
$break-xs:						375px;
$break-sm:						640px;
$break-md:						800px;
$break-lg:						1025px;
$break-xl:						1600px;

$mobile-portrait: 				max-width $break-xs;
$mobile-landscape: 				min-width $break-xs+1;
$mobile:						max-width $break-sm;

$tablet:						min-width $break-sm;
$tablet-portrait:				($break-sm $break-md) (orientation portrait);
$tablet-landscape:				($break-sm $break-md) (orientation landscape);

// Colours
$colour--black:					#291300;
$colour--white:					#fff;
$colour--brand:					#D16200;
$colour--secondary:				#2f4054;
$colour--success:				#109e69;
$colour--warning:				#dea00b;
$colour--error:					#de300b;
$colour--info:					#016FB9;
$colour--border:				#D9D9D9;
$colour--dark-secondary:		rgba(41,19,0,.6);

// Transparencies
$transparent-secondary:			.6;
$transparent-disabled: 			.3;
$transparent-none:				1;

// Radius
$radius:						3px;

// Font styles
$font-sizes: (
	display: 48px,
	headline: 32px,
	sub-header: 24px,
	body: 18px,
	caption: 16px,
	small: 13px
);

@function font-size($key) {
  @if map-has-key($font-sizes, $key) {
    @return map-get($font-sizes, $key);
  }

  @warn "Unknown `#{$key}` in $font-sizes.";
  @return null;
}

// Icons 
$fa-css-prefix:       icon;
$fa-font-path:        "../fonts";
//$fa-font-size-base:   16px;


// Navigation
$secondary-nav-height-small: 66px;
$secondary-nav-height: 66px;