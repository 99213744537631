// Nav

.nav {
	//margin-left: -$spacing;
}

.nav__item {
	font-size: font-size(caption);
  font-weight: 300;
}

.nav__link {
	display: block;
	padding: 0 $spacing;
	color: rgba(255,255,255,.6);

	& .icon {
		padding-right: $spacing-sm/2;
	}

	&:hover {
		color: $colour--white;
	}
}

.nav-link--active {
	color: $colour--white;
	border-bottom: 3px solid $colour--brand;
	
	.nav__label {
		opacity: 1;
	}
}

.nav--secondary {

  .nav__item {
    font-size: font-size(small);
  }

  .nav__link {
  	border: none;
  }
  
}

// Auto hiding nav

.cd-secondary-nav {
	position: relative;
	z-index: 1;
	clear: both;
	width: 100%;
	height: $secondary-nav-height-small;
	/* Force Hardware Acceleration */
	transform: translateZ(0);
	will-change: transform;
	transition: transform .5s;

	&::after {
		/* gradient on the right - to indicate it's possible to scroll */
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		height: 100%;
		width: 35px;
		background: transparent;
		background: linear-gradient(to left, lighten($colour--black,5%), rgba(lighten($colour--black,5%), 0));
		pointer-events: none;
		transition: opacity .2s;
	}

	&.nav-end::after {
		opacity: 0;
	}

	ul, li, a {
		height: 100%;
	}

	ul {
		/* enables a flex context for all its direct children */
		display: flex;
		//padding: 0 5%;
		//@include clearfix;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	li {
		display: inline-block;
		float: left;
		/* do not shrink - elements float on the right of the element */
		flex-shrink: 0;

		&:last-of-type {
			padding-right: 20px;
		}
	}

	a {
		display: block;
		//opacity: .6;
		line-height: $secondary-nav-height-small;
		//padding: 0 1em;

		&:hover, &.active {
			opacity: 1;
		}
	}

	@include breakpoint($break-lg) {
		height: $secondary-nav-height;
		overflow: visible;

		ul {
			/* reset mobile style */
			display: block;
			//text-align: center;
		}

		li {
			/* reset mobile style */
			float: none;
			flex-shrink: 1;
		}

		a {
			line-height: $secondary-nav-height;

			&.active {
			}
		}
	}
}