// Nav

.list {

	margin-left: 18px;

	&--inline {
		margin:  0;
	    padding: 0;
	    list-style: none;

	    > li {
	    	display: inline-block;
	    }

	}

	&--stripped {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&--with-icons {

		.list__item {
			position: relative;
			padding: 0;
			border-bottom: 1px solid $colour--border;
		}

		.list__link {
			margin-right: $spacing-md;
			padding: $spacing-xs 0;
		}

	}

	&__delete-item {
		position: absolute;
		top: 12px;
		right: 0;
		color: $colour--dark-secondary;
		cursor: pointer;

		&:hover {
			color: $colour--error;

			&:first-of-type + a {
				text-decoration: line-through;
				color: #ccc !important;
			}
		}
	}

	&__item {
		padding-bottom: $spacing-xs/2;
		font-size: font-size(caption);
	}

	&__link {
		color: $colour--dark-secondary;

		&:hover {
			color: $colour--black;
		}
	}

}

