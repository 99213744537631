// Floats

.float--right {
	float: right;
}

.float--left {
	float: left;
}

.float--none {
	float: none;
}