// Alerts

.alert {
	position: relative;
	padding: $spacing-sm $spacing-sm 0;
	
	p {
		margin: 0;
		padding-bottom: $spacing-sm;
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.alert--info {
	background-color: rgba($colour--info, .2);
	color: $colour--info;

	 a {
	 	color: $colour--info;

	 	&:hover {
	 		color: darken($colour--info,10%);
	 	}
	 }
}

.alert__dismiss {
	padding: 5px;
	position: absolute;
	top: 0;
	right: $spacing;
	opacity: .4;

	&:hover {
		opacity: 1;
	}
}