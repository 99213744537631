// Buttons

.btn {
  display: inline-block; 
  vertical-align: middle;
  font-size: font-size(caption);
  text-align: center;
  margin:  0;
  cursor: pointer;
  overflow: visible;
  padding: 18px $spacing-md 17px;
  border-radius: $radius;
  border: none; 
  color: $colour--white;

  @include breakpoint($break-sm) {
    font-size: font-size(body);
  }

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  &--primary {
  background-color: $colour--success;

    &,
    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }

    &:hover,
    &:focus {
      background-color: darken($colour--success,5%);
    }
  }

  &--secondary {
    background-color: #f2f2f2;
    border: 1px solid #D9D9D9;
    color: #D16200;

    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      background-color: darken(#F2F2F2,10%);
    }
  }

  &--facebook {
    background-color: #3b5998;

    &:hover,
    &:hover,
    &:active,
    &:focus {
      background-color: darken(#3b5998,10%);
    }
  }

  &--linkedin {
    background-color: #007bb5;

    &:hover,
    &:hover,
    &:active,
    &:focus {
      background-color: darken(#007bb5,10%);
    }
  }

  &--twitter {
    background-color: #4099FF;

    &:hover,
    &:hover,
    &:active,
    &:focus {
      background-color: darken(#4099FF,10%);
    }
  }

  &--small {
    padding: $spacing-xs $spacing-xs;
    font-size: font-size(small);

    @include breakpoint($break-sm) {
      padding: $spacing-xs $spacing-sm;
      font-size: font-size(caption);
    }
  }

  &--large {
  }

  &--block {
    width: 100%;
  }

}

.btn:disabled {
  cursor: not-allowed;
  opacity: .65;
}

.btn--secondary:disabled:hover {
  background-color: #F2F2F2;
}

.btn--stripped {
  border: none;
  background: none;
  -webkit-appearance: none;
  cursor: pointer;
  color: #D16200;
}


// Temp

.icon-fw.icon-linkedin-square {
  color: #007bb5;
}

.icon-fw.icon-twitter {
  color: #4099FF;
}

.icon-fw.icon-stack-overflow {
  color: #FF9900;
}

.icon-fw.icon-google-plus {
  color: #dd4b39;
}

.icon-fw.icon-xing {
  color: #1E636A;
}

.icon-fw.icon-github {
  color: #000;
}
