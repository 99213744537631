/**
	* Form

**/

.form {
	padding: 16px 0 48px;
}

@include breakpoint($break-lg) {

	.field--ad-optimiser {
		margin-bottom: 0;
	}

}

/**
	* Results

**/

.keywords {
	margin-bottom: 48px;
	padding: 24px 0;
	background: #F2F2F2;
}

.results__author-switch {
	position: absolute;
	top: 0;
	right: 0;
	padding: 6px 12px 5px 8px;
	font-size: 14px;
	color: #291300;
	background: white;
	border: 1px solid #D9D9D9;
	border-radius: 100px;
}

.results__author-switch:active, .results__author-switch:focus, .results__author-switch:hover {
	outline: none;
	border-color: rgba(41,19,0,.3);
	
}

.results__author-switch:before {
	padding-right: 8px;
	font-family: "FontAwesome";
	content: "\f111";
	opacity: .2;
}

.results__author-switch.collapsed:before {
	content: "\f058";
	color: #109E69;
	opacity: 1;
}

/**
	* Editor

**/

.editor {
	margin-bottom: 48px;
}

.characters {
	float: right;
}
