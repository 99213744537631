// Panels

.panel {
	margin-bottom: $spacing-md;

	&__title {
		padding-bottom: $spacing-sm;
		font-size: font-size(body);
	}

}

.panel--warning {
	padding: $spacing;
	border-radius: $radius;
	background-color: #F8ECCE;
	background-image: url('../images/background-illustration-main.png');
	background-size: 380px, contain;
	background-repeat: repeat-x;
	color: darken($colour--warning, 10%);
}

.panel--info {
	padding: $spacing;
	border-radius: $radius;
	background-color: #E5F0F8;
	background-image: url('../images/background-illustration-main.png');
	background-size: 380px, contain;
	background-repeat: repeat-x;
	color: darken($colour--info, 10%);

	& p:last-of-type {
		margin-bottom: 0;
	}
}