// Text

.text--truncate {
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	white-space: nowrap;
}

.text--right {
	text-align: right;
}

.text--screen-reader {
	position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding:0 !important;
    border:0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.text--strike-through {
	text-decoration: line-through;
}

.text--display {
	font-size: 48px;
	font-weight: 300;
}

.text--headline {
	font-size: 32px;
	font-weight: 300;
}

.text--sub-header {
	font-size: 24px;
	font-weight: 400;
}

.text--body {
	margin: 0 0 8px;
	padding: 0;
	font-size: 18px;
	font-weight: 400;
}

.text--caption {
	margin: 0 0 8px;
	padding: 0;
	font-size: 16px;
	font-weight: 400;
}

.text--small {
	margin: 0 0 12px;
	padding: 0;
	font-size: 13px;
	font-weight: 400;
}

.text--secondary {
	opacity: .6;
	font-weight: 400;
}

.text--bold {
	font-weight: 700;
}