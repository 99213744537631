// Tags

.tag-list {

}

.tag {
	position: relative;
	margin: 0 10px 10px 0;
	padding: 5px 8px;
	background: #fff;
	border: 1px solid #D9D9D9;
}

.tag__delete {
	padding: 2px;
	font-size: 13px;
	line-height: 1;
	color: #BFBFBF;
}

.tag__delete:hover {
	color: #DE300B;
}

.tag__link {
	color: #291300;
}

.tag__link:hover {
	color: #D16200;
}

.tag--used a {
	color: rgba(41,19,0,.3);
}

/* Backup
.tag--used:after {
	position: absolute;
	top: -7px;
	right: -7px;
	font-family: "FontAwesome";
	font-size: 14px;
	color: #109E69;
	content: "\f058";
}
*/

.tag__used-icon {
	position: absolute;
	top: -14px;
	right: -7px;
	width: 16px;
	height: 20px;
	font-size: 14px;
}

.tag__used-icon .fa-circle {
	color: #fff;
}

.tag__used-icon .fa-check-circle {
	color: #109E69;
}