// Hero

.hero {
	padding: 40px 0 84px;
}

.hero__title {
	font-size: font-size(headline);
	font-weight: 400;
	line-height: 48px;
	margin: 0;
	padding: 0;
}

.hero__subtitle {
	display: block;
	padding-top: $spacing-sm;
	font-size: font-size(sub-header);
	font-weight: 300;
	line-height: 32px;
	opacity: $transparent-secondary;
}