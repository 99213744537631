// Forms

.field {
  position: relative;
	margin-bottom: $spacing;

  @include breakpoint($break-md) {

    .field {
      margin-bottom: $spacing-lg;
    }

  }

	&__label {
		padding-bottom: $spacing-xs;
		display: block;
		font-size: font-size(body);
	}

	&__control {
		width: 100%;
		padding: $spacing-sm $spacing;
		margin-bottom: $spacing-xs;
		border: 1px solid $colour--border;
		border-radius: 3px;
		font-size: font-size(body);

		&:disabled {
			background-color: #E6E6E6;
			border-color: #E6E6E6;
			cursor: not-allowed;
		}

		&--input {
	
			&::placeholder {
				opacity: $transparent-disabled;
			}

			&:focus {
				background-color: #F7F7F7;
        border-color: #CCC;
				outline: none;
			}
		}

		&--select {
			
			height: 54px;

			&:focus {
				outline: none;
			}
		}

    &--textarea {
      min-height: 300px;
      padding: $spacing;

      &:focus {
        background-color: #F7F7F7;
        border-color: #CCC;
        outline: none;
      }
    }
	}

	&__help {
		position: absolute;
    top: 0;
    right: 0;

    .icon {
      opacity: $transparent-secondary;
    }

    &:hover {
      .icon {
        opacity: $transparent-none;
      }
    }
	}

	&__submit {}

	&__not-requried {
		display: inline-block;
		font-size: 12px;
		vertical-align: baseline;
	}
}

.field--with-help {
  .field__label {
    padding-right: $spacing;
  }
}

.field__control--container {
  max-width: 750px;
  margin: 0 auto;
}

// Select2 styling TEMP

.select2-container--focus {
  background-color: #F7F7F7;
  border-color: #CCC;
  outline: none;
}


.field--ad-optimiser .select2-container {
  margin: 0;
}

.select2-container {
  display: inline-block;
  margin: 0 0 $spacing-sm;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    cursor: pointer;
    display: block;
    padding: $spacing-sm $spacing;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      //padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid $colour--border;
  border-radius: $radius;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  font-size: font-size(body);
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: $spacing-sm $spacing;
  border-bottom: 1px solid $colour--border;
  user-select: none;
  -webkit-user-select: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: $spacing-sm $spacing $spacing-sm 48px; 

  &:before {
  	font-family: "FontAwesome";
  	content: "\f002";
  	position: absolute;
  	top: 19px;
  	left: $spacing;
  	opacity: $transparent-secondary;
  }

}
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  border: 1px solid $colour--border;
	border-radius: 3px;
	font-size: font-size(body);
  outline: none;
}
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    //color: #444;
    //line-height: 28px; 
}
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 13px;
    right: $spacing-sm;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: $colour--black transparent transparent transparent;
      border-style: solid;
      border-width: 7px 6px 0 6px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $colour--black transparent;
  border-width: 0 6px 7px 6px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  //padding: $spacing-xs $spacing-sm;
  
  &:focus {
  	outline: none;
  }

  &:before {
  	font-family: "FontAwesome";
  	content: "\f002";
  }
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 300px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true]:after {
  float: right;
  font-family: 'FontAwesome';
  content: "\f058";
  color: $colour--success;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #F2F2F2;
  //color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

// Custom Checkboxes and Radio buttons

.field--checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  line-height: 25.6px;
}

.field__control--checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.field__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border:1px solid $colour--border;
}
.field--radio .field__indicator {
  border-radius: 50%;
}

.field:hover input ~ .field__indicator,
.field input:focus ~ .field__indicator, {
  background: #ccc;
}

.field input:checked ~ .field__indicator {
  background: $colour--success;
  border-color: $colour--success;
}

.field:hover input:not([disabled]):checked ~ .field__indicator,
.field input:checked:focus ~ .field__indicator {
  background: darken($colour--success,10%);
}

.field input:disabled ~ .field__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.field__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.field input:checked ~ .field__indicator:after {
  display: block;
}

.field--checkbox .field__indicator:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.field--checkbox input:disabled ~ .field__indicator:after {
  border-color: #7b7b7b;
}

.field--radio .field__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.field--radio input:disabled ~ .field__indicator:after {
  background: #7b7b7b;
}